/* eslint-disable @typescript-eslint/no-explicit-any */
import { GlobalStyles } from '@hospy/hospy-ui';
import {
    HospyThemeProvider,
    SessionMonitoring,
    TranslateProvider,
    ValidateAuth
} from '@hospy/shared-components';
import { store } from '@hospy/store';
import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import './base-theme.css';
import App from './components/app/app';
import { IntercomProvider } from 'react-use-intercom';

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import ErrorBoundaryFallback from 'libs/hospy-ui/src/lib/organisms/error-boundary-fallback';
import FeedbackEnableCookies from 'libs/hospy-ui/src/lib/organisms/feedback-enable-cookies';

Sentry.init({
	dsn: 'https://ab4b71e6124c4527968cbff385f0aa4b@o4504636114927616.ingest.sentry.io/4504636116697088',
	tracesSampleRate: 1.0,
	// This sets the sample rate to be 10%. You may want this to be 100% while
	// in development and sample at a lower rate in production
	replaysSessionSampleRate: 0.1,
	// If the entire session is not sampled, use the below sample rate to sample
	// sessions when an error occurs.
	replaysOnErrorSampleRate: 1.0,
	integrations: [
		new BrowserTracing()
		///new Sentry.Replay()
	],
	environment: process.env['NODE_ENV'] || 'development'
});

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

root.render(
	<StrictMode>
		<Provider store={store}>
			<GlobalStyles />
			<FeedbackEnableCookies>
				<ValidateAuth>
					<BrowserRouter>
						<SessionMonitoring />
						<HospyThemeProvider>
							<TranslateProvider
								app="spa"
								defaultMessagesFunction={
									import(
										'./translations/compiled-lang/es.json'
									)
								}
							>
								<Sentry.ErrorBoundary
									fallback={(props) => (
										<ErrorBoundaryFallback {...props} />
									)}
								>
									<IntercomProvider
										autoBoot={true}
										appId="kpy3ar74"
									>
										<App />
									</IntercomProvider>
								</Sentry.ErrorBoundary>
							</TranslateProvider>
						</HospyThemeProvider>
					</BrowserRouter>
				</ValidateAuth>
			</FeedbackEnableCookies>
		</Provider>
	</StrictMode>
);
